<template>
  <v-container fluid class="d-flex flex-column fill-height">
    <v-card
      v-if="loaded"
      tile
      flat
      class="elevation-0 overflow-y-auto ma-auto ml-10 mr-10"
      height="calc(89vh - 80px)"
      max-width="90vw"
    >
      <alert
        class="mt-2"
        dense
        color="warning"
        v-if="!onboardingUser.is_gmail_enabled"
      >
        <div class="d-flex flex-inline align-center justify-space-between">
          <span>{{ $t("common.inactiveUserInWorkspaceAlert") }}</span>

          <div :class="`${$vuetify.breakpoint.smAndDown ? '' : 'd-flex'}`">
            <v-btn
              text
              small
              color="secondary lighten-2"
              class="mr-2 text-body-2 font-weight-medium"
              @click="
                redirectToGoogleUserInWorkspace(
                  onboardingUser.id_google,
                  'licenses'
                )
              "
              :loading="loadingCheckUser"
            >
              {{ $t("action.assignLicense") }}
              <v-icon
                right
                v-text="'ph-arrow-square-out'"
                class="mr-3"
                size="20"
              />
            </v-btn>
            <v-btn
              text
              small
              outlined
              color="warning"
              :class="`ml-auto text-body-2 font-weight-medium ${
                $vuetify.breakpoint.smAndDown ? 'mt-2' : ''
              }`"
              @click="checkUserById()"
              :loading="loadingCheckUser"
            >
              {{ $t("action.reloadUserInfo") }}
              <v-icon
                right
                v-text="'ph-arrow-clockwise'"
                class="mr-3"
                size="20"
              />
            </v-btn>
          </div>
        </div>
      </alert>
      <v-card-title class="pa-2 ml-16">
        {{ $t("onboardingNewUser.companyCalendars") }}
      </v-card-title>
      <v-row style="width: 89vw" class="ma-0 pa-0" justify="center">
        <v-col
          :cols="`${$vuetify.breakpoint.mdAndUp ? '11' : '12'}`"
          class="mt-0"
        >
          <UserCalendar :isPanelOpened="true" outlined :user="onboardingUser" />
        </v-col>
      </v-row>
      <v-card-title class="pa-2 ml-16">
        {{ $t("common.companyDrives") }}
      </v-card-title>
      <v-row style="width: 89vw" class="ma-0 pa-0" justify="center">
        <v-col
          :cols="`${$vuetify.breakpoint.mdAndUp ? '11' : '12'}`"
          class="mt-0"
        >
          <UserSharedDrives
            :isPanelOpened="true"
            outlined
            :user="onboardingUser"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card
      v-else
      tile
      flat
      class="elevation-0 overflow-y-auto ma-auto ml-10 mr-10"
      height="calc(89vh - 80px)"
      max-width="90vw"
    >
      <v-row class="ma-0 pa-0" style="width: 90vw">
        <v-col
          v-for="key in [1]"
          :key="key"
          class="pt-0"
          cols="12"
          sm="12"
          md="12"
          lg="12"
        >
          <v-progress-linear
            v-if="!loaded"
            indeterminate
            color="primary"
            class="mb-2"
          ></v-progress-linear>
          <div><v-skeleton-loader type="text" /></div>
          <div><v-skeleton-loader type="image" /></div>
        </v-col>
      </v-row>
    </v-card>
    <v-divider style="width: 90vw"></v-divider>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3"
      width="90vw"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="mx-auto text-none text-body-1 font-weight-medium px-8"
        outlined
        @click="$emit('back')"
      >
        <v-icon right v-text="'ph-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-spacer />

      <v-btn
        height="52"
        color="accent"
        class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
        @click="$emit('next')"
      >
        {{ $t("action.next") }}
        <v-icon right v-text="'ph-arrow-right'" class="ml-4" size="22" />
      </v-btn>
    </v-footer>
  </v-container>
</template>
<script>
import UserCalendar from "@/components/user/UserCalendar.vue";
import UserSharedDrives from "@/components/user/UserSharedDrives.vue";
import { redirectToGoogleUserInWorkspace } from "@/helpers/services/users";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EnterpriseResources",
  components: {
    UserCalendar,
    UserSharedDrives,
  },

  computed: {
    ...mapGetters(["allCalendars", "onboardingUser", "loadingCheckUser"]),

    loaded() {
      return this.onboardingUser.id_google;
    },
  },

  methods: {
    ...mapActions(["getCalendars", "checkUserById"]),

    redirectToGoogleUserInWorkspace,
  },
  async beforeMount() {
    if (!this.allCalendars.length && this.onboardingUser.is_gmail_enabled) {
      this.getCalendars();
    }
  },
};
</script>
<style scoped></style>
