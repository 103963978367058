<template>
  <v-container fluid class="d-flex flex-column fill-height">
    <div>
      <v-row
        :class="`${
          $vuetify.breakpoint.xs
            ? 'd-flex flex-column'
            : 'd-flex align-center flex-wrap'
        } mx-0 pa-0 my-0 py-0 mb-1`"
      >
        <v-col cols="12" class="py-0 px-0 d-flex align-center">
          <div>
            <v-card-title class="pa-0 mt-3">
              {{ $t("onboardingNewUser.shifts") }}
            </v-card-title>
            <v-card-text class="pa-2 ml-3">
              <p class="ma-0">
                {{ $t("onboardingNewUser.controlShift.infoAboutThisStep") }}
              </p>
              <p class="ma-0">
                {{ $t("onboardingNewUser.controlShift.createShift") }}
              </p>
            </v-card-text>
          </div>
          <v-spacer />
        </v-col>
      </v-row>
      <!-- CONTROL SHIFTS -->
      <v-card outlined max-width="1400" class="mx-auto rounded-lg" flat>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="hasControlPlan ? controlShifts : []"
          item-key="key"
          :search="search"
          show-select
          checkbox-color="accent"
          fixed-header
          :sort-by="['name']"
          :loading="loading || loadingGroups"
          @toggle-select-all="getPageSelection"
          :class="`${$vuetify.theme.dark ? 'background' : ''} elevation-0`"
          :style="`${
            $vuetify.theme.dark
              ? 'border-bottom: 1px solid #424242 !important'
              : ''
          }`"
          :items-per-page="itemsPerPage"
          :footer-props="{
            itemsPerPageOptions: itemsOptions,
            itemsPerPageText: $t('common.linesPerPage'),
          }"
          :height="tableHeight"
          mobile-breakpoint="0"
          :no-data-text="$t('common.noDataAvailable')"
        >
          <template
            v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
          >
            {{ `${pageStart}-${pageStop}` }}
            {{ `${$t("common.of")} ${itemsLength}` }}
          </template>
          <template v-slot:top>
            <v-row
              v-if="hasSelectedGroups"
              :class="`${
                $vuetify.theme.dark ? 'grey darken-2' : 'orange lighten-5'
              } d-flex align-center px-2 my-0 py-0 ma-0`"
              style="border-radius: 7px 7px 0px 0px"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-2"
                    icon
                    @click="clearSelectedGroups()"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon v-text="'ph-x'" />
                  </v-btn>
                </template>
                <span class="caption">{{
                  $t("onboardingNewUser.resetSelection")
                }}</span>
              </v-tooltip>
              <v-card-title>
                {{ selectedGroupsNumber }}
                {{ $t("onboardingNewUser.selectedShifts") }}
              </v-card-title>
            </v-row>

            <v-row v-else class="mx-2 my-0 py-0">
              <v-col
                cols="12"
                :class="`${
                  $vuetify.breakpoint.xs
                    ? ''
                    : 'd-flex align-center justify-end'
                } py-0 px-0`"
              >
                <v-card-subtitle
                  :class="`
                ${
                  $vuetify.theme.dark
                    ? 'grey--text text--lighten-5'
                    : 'grey--text text--darken-2'
                }
                text-body-1
              `"
                >
                  {{ $t("onboardingNewUser.showingAllShifts") }}
                </v-card-subtitle>

                <v-divider
                  vertical
                  class="my-0"
                  v-if="!$vuetify.breakpoint.xs"
                />
                <v-btn
                  text
                  outlined
                  color="accent"
                  class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
                  @click="getShifts()"
                  :loading="loading || loadingGroups"
                >
                  {{ $t("action.reloadShifts") }}
                  <v-icon
                    right
                    v-text="'ph-arrow-clockwise'"
                    class="ml-4"
                    size="22"
                  />
                </v-btn>
              </v-col>
            </v-row>
            <v-divider />
            <v-row
              v-if="allSelectedAreInTheSamePage || allSelected"
              :class="`${
                $vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3'
              } d-flex align-center justify-center px-2 my-0 py-2 ma-0 text-body-2`"
              :style="$vuetify.breakpoint.mdAndUp ? 'height: 40px' : ''"
            >
              <!-- TEXTO QUE APARECE QUANDO TODOS OS ITEMS DA PÁGINA ESTÃO SELECIONADOS -->
              <div
                v-if="allSelectedAreInTheSamePage && !allSelected"
                class="d-flex align-center text-wrap"
              >
                {{ $t("onboardingNewUser.all") }}
                <span class="font-weight-medium mx-1">{{
                  selectedGroupsNumber
                }}</span>
                {{ $t("onboardingNewUser.shiftsSelectedsInPage") }}
                <v-btn
                  text
                  x-small
                  color="info darken-2"
                  :block="$vuetify.breakpoint.sm"
                  class="text-none font-weight-medium text-body-2 px-1 py-0 my-0"
                  @click="selected = controlShifts.slice()"
                >
                  {{ $t("onboardingNewUser.selectAllShifts") }}
                </v-btn>
              </div>

              <!-- TEXTO QUE APARECE QUANDO TODOS OS ITENS DA TABELA ESTÃO SELECIONADOS -->
              <div v-if="allSelected" class="d-flex align-center text-wrap">
                {{ $t("onboardingNewUser.all") }}
                <span class="font-weight-medium mx-1">{{
                  selectedGroupsNumber
                }}</span>
                {{ $t("onboardingNewUser.shiftsSelectedsInTable") }}
                <v-btn
                  text
                  x-small
                  color="info darken-2"
                  class="text-none font-weight-medium text-body-2 px-1 py-0 my-0"
                  @click="clearSelectedGroups()"
                >
                  {{ $t("onboardingNewUser.clearSelection") }}
                </v-btn>
              </div>
            </v-row>
            <v-divider v-if="allSelectedAreInTheSamePage || allSelected" />
            <v-row style="max-height: 72px" class="px-0">
              <v-text-field
                v-model="search"
                :placeholder="$t('onboardingNewUser.searchShift')"
                class="elevation-0 mx-1 mt-4 mb-0 transparent"
                background-color="transparent"
                filled
                dense
                rounded
                height="25"
                full-width
                persistent-placeholder
              >
                <template v-slot:prepend-inner>
                  <v-icon
                    v-text="'ph-magnifying-glass'"
                    class="my-0 mr-4"
                    left
                  />
                </template>
              </v-text-field>
            </v-row>
            <v-divider />
          </template>
          <template v-slot:loading> {{ $t("common.loading") }} </template>
          <template v-slot:no-results> {{ $t("common.noResults") }} </template>

          <!-- INÍCIO: HEADERS -->
          <template v-slot:[`header.name`]="{}">
            {{ $t("common.name") }}
          </template>

          <template v-slot:[`header.is_active`]="{}"> Status </template>
          <template v-slot:[`header.users.length`]="{}">
            {{ $t("onboardingNewUser.participants") }}
          </template>

          <template v-slot:[`header.days_period_to_expire_password`]="{}">
            {{ $t("onboardingNewUser.passwordPolicy") }}
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                  small
                  v-text="'ph-fill ph-info'"
                />
              </template>
              <span>
                {{ $t("onboardingNewUser.passwordPolicyTooltipText") }}
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`header.pontomais_login_rule`]="{}">
            {{ $t("onboardingNewUser.login") }}
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                  small
                  v-text="'ph-fill ph-info'"
                />
              </template>
              <span>
                {{ $t("onboardingNewUser.loginTooltipText") }}
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`header.auto_logout`]="{}">
            {{ $t("onboardingNewUser.logout") }}
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                  small
                  v-text="'ph-fill ph-info'"
                />
              </template>
              <span>
                {{ $t("onboardingNewUser.logoutTooltipText") }}
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <span class="blue--text text--darken-2 link">
              {{ item.name }}
            </span>
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <span class="d-flex align-center">
              <v-icon
                class="mr-1"
                :color="item.is_active ? 'success' : 'error'"
                size="20"
              >
                {{ item.is_active ? "ph-check-circle" : "ph-x-circle" }}
              </v-icon>
              {{ item.is_active ? "Ativo" : "inativo" }}
            </span>
          </template>
          <template v-slot:[`item.pontomais_login_rule`]="{ item }">
            <span class="d-flex align-center">
              {{
                item.pontomais_login_rule
                  ? $t("onboardingNewUser.pointControl")
                  : "-"
              }}
            </span>
          </template>
          <template v-slot:[`item.auto_logout`]="{ item }">
            <span class="d-flex align-center">
              {{ getLogoutText(item) }}
            </span>
          </template>
          <template v-slot:[`item.days_period_to_expire_password`]="{ item }">
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on: tooltip }">
                <span
                  v-if="item.days_period_to_expire_password"
                  class="d-flex align-center"
                  v-on="{ ...tooltip }"
                >
                  {{
                    item.days_period_to_expire_password
                      ? `${$t("onboardingNewUser.changeEvery")} ${
                          item.days_period_to_expire_password
                        } ${$t("onboardingNewUser.days")}`
                      : "-"
                  }}
                </span>
                <span v-else class="d-flex align-center">-</span>
              </template>
              <span>
                {{ $t("onboardingNewUser.nextPasswordChange") }}
                {{ formatDate(item.date_to_expire_password) }}</span
              >
            </v-tooltip>
          </template>
          <template slot="no-data">
            <div
              v-if="!hasControlPlan"
              class="px-0 mx-auto d-flex justify-center"
            >
              <InviteToUpsell
                analyticsClick="shifts_step_create_user"
                invitationText="vacationApply.manageAccess.upsellInvitation"
                analyticsDescription="Conheça os planos no step de controle de acesso no fluxo de criação de usuário"
              />
            </div>
            <div
              v-for="(item, i) in emptyStateCards"
              :key="i"
              class="d-flex align-center justify-center mt-4"
            >
              <EmptyStateCard
                v-if="item.show"
                :id="item.id"
                :title="item.title"
                :subtitle="item.subtitle"
                :buttonText="item.buttonText"
                :buttonIcon="item.buttonIcon"
                @action="item.action"
              />
            </div>
          </template>
        </v-data-table>
        <v-snackbar v-model="showSnackbarAllSelected" bottom>
          {{ $t("onboardingNewUser.allShiftsSelected") }}
          <template v-slot:action="{ attrs }">
            <v-btn
              dark
              ext
              v-bind="attrs"
              @click="showSnackbarAllSelected = false"
            >
              {{ $t("action.close") }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </div>

    <v-divider style="width: 90vw"></v-divider>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3"
      width="90vw"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="mx-auto text-none text-body-1 font-weight-medium px-8"
        outlined
        @click="$emit('back')"
        :disabled="loading"
      >
        <v-icon right v-text="'ph-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-spacer />
      <div>
        <v-btn
          height="52"
          color="accent"
          class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
          @click="handleApply"
          :loading="loading"
          :disabled="loading"
        >
          {{ $t("action.next") }}
          <v-icon right v-text="'ph-arrow-right'" class="ml-4" size="22" />
        </v-btn>
      </div>
    </v-footer>
  </v-container>
</template>
<script>
import EmptyStateCard from "@/components/general/EmptyStateCard.vue";
import InviteToUpsell from "@/components/purchase/InviteToUpsell.vue";
import { formatDate } from "@/helpers/services/utils";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { paging } from "@/helpers/variables/tablesDefault";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "AddShift",
  components: { InviteToUpsell, EmptyStateCard },

  props: {},

  data() {
    return {
      loading: false,
      loadingGroups: false,
      showSnackbarAllSelected: false,
      selected: [],
      search: "",
      allItemsInThePage: [],
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      tableHeight: "calc(80vh - 290px)",

      userApplyShiftStep: 6,
    };
  },

  computed: {
    ...mapGetters([
      "controlShifts",
      "companyHasControl",
      "company",
      "currentOnboardingStep",
      "onboardingUser",
    ]),

    hasSelectedGroups() {
      return this.selected.length > 0;
    },

    selectedGroupsNumber() {
      return this.selected.length;
    },

    allSelectedAreInTheSamePage() {
      if (this.selected.length > 0) {
        return (
          this.selected.every((item) =>
            this.allItemsInThePage.includes(item)
          ) && this.allItemsInThePage.length === this.selected.length
        );
      } else {
        return false;
      }
    },

    allSelected() {
      if (this.selected.length == 0) {
        return false;
      }

      return this.controlShifts.length === this.selected.length;
    },

    headers() {
      return [
        {
          align: "start",
          sortable: true,
          value: "name",
          width: 250,
        },
        {
          value: "is_active",
          width: 150,
        },
        {
          value: "users.length",
          sortable: false,
          width: 130,
        },
        {
          value: "pontomais_login_rule",
          width: 200,
        },
        {
          value: "auto_logout",
          width: 210,
        },
        {
          value: "days_period_to_expire_password",
          width: 210,
        },
      ];
    },

    hasControlPlan() {
      const not_valid_status = [
        "PLAN_TRIAL_ENDED",
        "PRODUCT_DOES_NOT_EXIST_IN_PLANS",
      ];
      if (not_valid_status.includes(this.companyHasControl)) return false;
      else return true;
    },

    hasAccessedControl() {
      return !!this.company.control_last_seen;
    },

    emptyStateCards() {
      return [
        {
          show: this.hasControlPlan && !this.hasAccessedControl,
          id: "has-no-accessed-control",
          title: "onboardingNewUser.controlShift.hasNotAccessedControlYet",
          subtitle:
            "onboardingNewUser.controlShift.hasNotAccessedControlYetDescription",
          buttonText: "sidebar.conectaControl",
          buttonIcon: "ph-arrow-square-out",
          action: () => this.goToControl(),
        },
        {
          show:
            !this.controlShifts.length &&
            this.hasAccessedControl &&
            this.hasControlPlan,
          id: "has-no-shifts",
          title: "onboardingNewUser.controlShift.noShifts",
          subtitle: "",
          buttonText: "action.createShifts",
          buttonIcon: "ph-arrow-square-out",
          action: () => this.goToControl("/groups"),
        },
      ];
    },
  },

  methods: {
    ...mapActions(["getControlShifts", "addUserToShifts"]),

    ...mapMutations(["setSnackBar", "setControlShifts"]),

    formatDate,

    clearSelectedGroups() {
      this.selected = [];
    },

    handleApply() {
      if (this.hasSelectedGroups && this.hasControlPlan) {
        this.save();
      } else if (this.userApplyShiftStep === this.currentOnboardingStep) {
        this.$emit("next");
      }
    },

    getLogoutText(item) {
      if (
        !item.advanced_auto_logout &&
        !item.auto_logout &&
        item.pontomais_auto_logout_rules.length == 0
      ) {
        return "-";
      } else {
        let text = "";
        if (item.auto_logout) {
          text += "Auto Logout";
        }
        if (item.advanced_auto_logout) {
          text += "Advanced Logout";
        }
        if (
          (item.auto_logout || item.advanced_auto_logout) &&
          item.pontomais_auto_logout_rules.length > 0
        ) {
          text += " / VR Gente | PontoMais Logout";
        } else if (item.pontomais_auto_logout_rules.length > 0) {
          text += "VR Gente | PontoMais Logout";
        }
        return text.trim();
      }
    },

    getPageSelection(event) {
      if (event.value) {
        this.allItemsInThePage = event.items;
      }
    },

    async save() {
      this.loading = true;
      const rule_groups = this.selected.map((group) => group.key);

      const payload = {
        rule_groups,
        email: this.onboardingUser.email,
      };

      this.addUserToShifts(payload)
        .then(() => {
          this.setSnackBar({
            show: true,
            message: `${this.onboardingUser.name} ${this.$t(
              "common.userAddedToShifts"
            )}`,
            color: "success",
          });

          if (this.userApplyShiftStep === this.currentOnboardingStep) {
            this.$emit("next");
          }
        })
        .catch((error) => {
          console.error(error);
          const { data } = error.response;

          const didNotFindUserInControl = data.code == "USERS_NOT_FOUND";

          const { USER_NOT_FOUND_IN_CONTROL, unknown } = errorMessages;

          this.setSnackBar({
            show: true,
            message: didNotFindUserInControl
              ? USER_NOT_FOUND_IN_CONTROL
              : unknown,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getShifts() {
      this.loadingGroups = true;
      await this.getControlShifts()
        .then(({ data }) => {
          this.setControlShifts(data);
        })
        .catch((error) => {
          console.error("getShifts(): ", error);
        })
        .finally(() => {
          this.loadingGroups = false;
        });
    },
  },

  watch: {
    allSelected() {
      if (this.allSelected) {
        this.showSnackbarAllSelected = true;
        this.tableHeight = "calc(75vh - 290px)";
      } else {
        this.allItemsInThePage = [];
        this.selected = [];
        this.tableHeight = "calc(80vh - 290px)";
      }
    },
  },

  async beforeMount() {
    if (this.controlShifts.length) {
      return;
    }

    this.getShifts();
  },
};
</script>
